.Text {
  &_type {
    &_h1 {
      margin: 10px 0;
      font-size: 22px;
    }
  }
}

@media (min-width: 480px) {
  .Text {
    &_type {
      &_h1 {
      font-size: 32px;
      }
    }
  }
}
