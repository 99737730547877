$maxTxtColumnWidth: 780px;
$topPaneHeightDesktop: 50px;
$pageFooterTopMarginMobile: 32px;
$pageFooterTopMarginDesktop: 64px;

$spacingSm: 8px;
$spacingMd: 16px;
$spacingLg: 24px;

$radiusSm: 4px;
$radiusMd: 6px;
$radiusLg: 8px;

// Colors
$colorLink: #1747e3;
$colorVisited: #5f17e3;
// $colorVisited: #6a3fbb;
$colorFocusOutline: #ea0;
$colorGrayBg: #f5f5f5;
