@import '../../global/styles.scss';

.Hero {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: calc(100vh - 74px - 32px);
}

.Hero__linksList {
  padding-left: 0;
  list-style: none;
}

.Hero__catalogLink {
  margin-bottom: $spacingSm;
}

@media (min-width: 768px) {
  .Hero {
    min-height: calc(100vh - 74px - 64px);
  }
}
